import Vue from 'vue'
import VueRouter from 'vue-router'
// import routeGuard from "./routeGuard";
import Chart from '@/components/Chart'
import HeatMap from '@/components/HeatMap'
import DashBoard from '@/components/DashBoard'
import SummaryDashBoard from "@/components/SummaryDashBoard";
import Markup from "@/components/Markup.vue";
import DashBoardLiq from "@/components/DashBoardLiq.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/ch',
        name: 'chart',
        component: Chart,
        // beforeEnter: routeGuard
    },
    {
        path: '/hm',
        name: 'heatmap',
        component: HeatMap,
        // beforeEnter: routeGuard
    },
    {
        path: '/markup',
        name: 'markup',
        component: Markup,
        // beforeEnter: routeGuard
    },
    {
        path: '/db',
        name: 'dashboard',
        component: DashBoard,
        // beforeEnter: routeGuard
    },
    {
        path: '/ldb',
        name: 'liq_dashboard',
        component: DashBoardLiq,
        // beforeEnter: routeGuard
    },
    {
        path: '/sdb',
        name: 'summary_dashboard',
        component: SummaryDashBoard,
        // beforeEnter: routeGuard
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/'
})

export default router
